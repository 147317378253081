var juridicalPersons = [{
  "rut": "76269703-3",
  "company": "A PLUS AJUSTADORES SPA"
}, {
  "rut": "77035807-8",
  "company": "ABACO CHILE LIQUIDADORES DE SEGUROS SPA"
}, {
  "rut": "76171561-5",
  "company": "ACC AJUSTADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76089044-8",
  "company": "ADDVALORA GLOBAL LOSS ADJUSTERS CHILE SPA"
}, {
  "rut": "76174388-0",
  "company": "ALDO ROMERO URRUTIA LIQUIDADOR DE SEGUROS E.I.R.L."
}, {
  "rut": "76099194-5",
  "company": "ARC AJUSTADORES LIMITADA"
}, {
  "rut": "77482428-6",
  "company": "ARM ADJUSTERS CHILE SPA"
}, {
  "rut": "76663768-K",
  "company": "ATD LIQUIDADORES SPA"
}, {
  "rut": "76547399-3",
  "company": "AUXILIA CLUB ASISTENCIA LIQUIDADORES DE SEGUROS SPA"
}, {
  "rut": "77125526-4",
  "company": "BARBUSS LIQUIDADORES OFICIALES DE SEGUROS LIMITADA"
}, {
  "rut": "77539884-1",
  "company": "BECERRA Y HERNANDEZ LIQUIDADORES OFICIALES SPA"
}, {
  "rut": "96801300-9",
  "company": "BECKETT S.A LIQUIDADORES DE SEGUROS"
}, {
  "rut": "76825268-8",
  "company": "BUSINESS PRO AJUSTADORES SPA"
}, {
  "rut": "76455624-0",
  "company": "CARLOS SANDOVAL BRAVO LIQUIDADORES E.I.R.L."
}, {
  "rut": "77342510-8",
  "company": "CARTER Y SAAVEDRA AJUSTADORES LIMITADA"
}, {
  "rut": "77911680-8",
  "company": "CAVE LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76163401-1",
  "company": "CFS Y ASOCIADOS LIQUIDADORES DE SEGUROS SOCIEDAD ANONIMA"
}, {
  "rut": "78734200-0",
  "company": "CHARLES TAYLOR CHILE S.A."
}, {
  "rut": "76193993-9",
  "company": "CORREA Y SILVA LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76619328-5",
  "company": "COURDURIER, HUBE Y COMPAÑIA LIMITADA"
}, {
  "rut": "77250330-K",
  "company": "CRAWFORD LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76157357-8",
  "company": "E.G.A. GROUP LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76154337-7",
  "company": "EDIAZ Y ASOCIADOS AJUSTADORES LIMITADA"
}, {
  "rut": "77382320-0",
  "company": "FRANCO SANGUINETTI LIQUIDADORES ASOCIADOS LIMITADA"
}, {
  "rut": "77250490-K",
  "company": "GRAHAM MILLER LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "79513810-2",
  "company": "GUERRERO Y COMPAÑIA LIMITADA"
}, {
  "rut": "76938101-5",
  "company": "HERNANDEZ Y ASOCIADOS AJUSTADORES LIMITADA"
}, {
  "rut": "78111050-7",
  "company": "J.A. CONTADOR Y COMPAÑIA LIMITADA"
}, {
  "rut": "77112986-2",
  "company": "JCR LIQUIDADORES SPA"
}, {
  "rut": "76356431-2",
  "company": "JGZ AJUSTADORES LIMITADA"
}, {
  "rut": "52005227-5",
  "company": "JOSE BERVA CARAFI LIQUIDADOR DE SINIESTROS E.I.R.L."
}, {
  "rut": "78938920-9",
  "company": "JPV ASOCIADOS AJUSTADORES ESPECIALIZADOS LIMITADA"
}, {
  "rut": "76134797-7",
  "company": "JUAN PABLO DUHALDE BEDDIG LIQUIDADORES INTERNACIONALES LIMIT"
}, {
  "rut": "76005895-5",
  "company": "LARRAÑAGA LIQUIDADORES Y COMPAÑIA SPA"
}, {
  "rut": "77017143-1",
  "company": "LIQUIDADORA DE SEGUROS VERITEC SPA"
}, {
  "rut": "76450400-3",
  "company": "LIQUIDADORES DE SEGUROS EN RED CARVALLO LIMITADA"
}, {
  "rut": "78479350-8",
  "company": "LIQUIDADORES DE SEGUROS SOCAL LIMITADA"
}, {
  "rut": "76522677-5",
  "company": "LIQUIDADORES OFICIALES DE SEGUROS Y SINIESTROS LIMITADA"
}, {
  "rut": "76763605-9",
  "company": "LT ASOCIADOS LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76023194-0",
  "company": "M PRO LIQUIDADORA DE SEGUROS LIMITADA"
}, {
  "rut": "77619200-7",
  "company": "MACHARD AJUSTADORES, LIQUIDADORES OFICIALES DE SEGUROS LTDA."
}, {
  "rut": "76163225-6",
  "company": "MADRIGAL SWAIN LIQUIDADORES DE SEGUROS SPA"
}, {
  "rut": "76629340-9",
  "company": "MARIO ARTIGUES LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "78346390-3",
  "company": "MCLARENS CHILE (LIQUIDADORES OFICIALES) SPA"
}, {
  "rut": "76273096-0",
  "company": "MONTT LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76109571-4",
  "company": "NUEVA ERA AJUSTADORES LIQUIDADORA DE SEGUROS LIMITADA"
}, {
  "rut": "79606130-8",
  "company": "ORGANIZACION MUESCHEN Y CIA. LTDA."
}, {
  "rut": "76318142-1",
  "company": "PAREDES LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76328771-8",
  "company": "PEDRO ZEGERS Y COMPAÑIA LIMITADA"
}, {
  "rut": "76495515-3",
  "company": "PROBITAS LIQUIDADORES OFICIALES DE SEGUROS LIMITADA"
}, {
  "rut": "76592236-4",
  "company": "QUALYTAS LIQUIDADORES DE SEGUROS SPA"
}, {
  "rut": "76339369-0",
  "company": "RED AJUSTADORES DE SINIESTROS SPA"
}, {
  "rut": "76250635-1",
  "company": "RED LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "78873240-6",
  "company": "RENE EYHERALDE Y COMPAÑIA LIMITADA"
}, {
  "rut": "76125434-0",
  "company": "RTS CHILE INTERNATIONAL LOSS ADJUSTERS SPA"
}, {
  "rut": "77475315-K",
  "company": "RYR LIQUIDADORES DE SEGUROS SPA"
}, {
  "rut": "96802220-2",
  "company": "S.G.C. TRANSPORTE LIQUIDADORES DE SEGUROS S.A."
}, {
  "rut": "96511620-6",
  "company": "SERVICIOS INTERNACIONALES DE INSPECCION S.A."
}, {
  "rut": "96865840-9",
  "company": "SGC AJUSTADORES S.A."
}, {
  "rut": "76478730-7",
  "company": "SGC LINEAS PERSONALES - MASIVOS SOCIEDAD ANONIMA"
}, {
  "rut": "96923030-5",
  "company": "SGC RECURSOS NATURALES SOCIEDAD ANÓNIMA"
}, {
  "rut": "76122083-7",
  "company": "SOCIEDAD LIQUIDADORA DE SINIESTROS S&F LIMITADA"
}, {
  "rut": "77610084-6",
  "company": "SOLUCIONES GENERALES DE SINIESTROS SPA"
}, {
  "rut": "76059290-0",
  "company": "TRIVAL AJUSTADORES OFICIALES DE SEGUROS LIMITADA"
}, {
  "rut": "77175298-5",
  "company": "TRUBERT SPA"
}, {
  "rut": "96594270-K",
  "company": "VIOLLIER Y ASOCIADOS LIQUIDADORES DE SEGUROS LIMITADA"
}, {
  "rut": "76159472-9",
  "company": "WAISMAN & WAISMAN LIMITADA"
}, {
  "rut": "76430866-2",
  "company": "ZIEL LIQUIDADORES DE SEGUROS LIMITADA"
}];
var naturalPersons = [{
  "rut": "7442464-3",
  "company": "ADASME RUIZ-TAGLE JUAN PAULO"
}, {
  "rut": "10696129-8",
  "company": "ALARCON BENAVIDES CRISTIAN ENRIQUE"
}, {
  "rut": "7777916-7",
  "company": "ALONSO ZUÑIGA CARLOS FELIPE"
}, {
  "rut": "10912187-8",
  "company": "ALTMANN COBOS FELIPE EDUARDO"
}, {
  "rut": "11482367-8",
  "company": "ALVAREZ GOMEZ ROBERTO CARLOS"
}, {
  "rut": "10632507-3",
  "company": "ARANCIBIA BUSTAMANTE JUAN MAURICIO"
}, {
  "rut": "8384308-K",
  "company": "ARAYA MARTINEZ FRANCISCO JAVIER"
}, {
  "rut": "8920838-6",
  "company": "AUBEL BARAHONA RICARDO ALEJANDRO"
}, {
  "rut": "13907069-0",
  "company": "AWAD GIAVERINI ANGELO YAMIL"
}, {
  "rut": "6370145-9",
  "company": "AWAD RIVAS RICARDO EMILIO"
}, {
  "rut": "11687570-5",
  "company": "BARRUETO OAKLEY ALBERTO EDGARDO"
}, {
  "rut": "14578638-K",
  "company": "BEST CLEMENS MARIA"
}, {
  "rut": "7003778-5",
  "company": "BRAVO GUARELLO CARLOS EDUARDO"
}, {
  "rut": "8099746-9",
  "company": "BRICEÑO FUENTES RUBEN DARIO"
}, {
  "rut": "8514017-5",
  "company": "BRIEBA FUENTES CHRISTIAN IGNACIO"
}, {
  "rut": "10849830-7",
  "company": "BURGOS MUÑOZ RAFAEL EUGENIO"
}, {
  "rut": "7233109-5",
  "company": "BUSTAMANTE NAVARRO PATRICIO ENRIQUE"
}, {
  "rut": "7423178-0",
  "company": "CARDENAS VASQUEZ MAXIMO ROLANDO"
}, {
  "rut": "8003612-4",
  "company": "CARRASCO SANCHEZ CRISTIAN ALFREDO"
}, {
  "rut": "25409306-8",
  "company": "CASTILLO PULIDOR CESAR GUILLERMO"
}, {
  "rut": "9902006-7",
  "company": "CATALDO CHAVEZ LUIS RAFAEL"
}, {
  "rut": "8282210-0",
  "company": "CHACON ROA JULIO ANIBAL"
}, {
  "rut": "10835420-8",
  "company": "CHUAQUI SCHADOW GABRIEL ALEJANDRO"
}, {
  "rut": "14427141-6",
  "company": "COMPER RIZO LUIGINA"
}, {
  "rut": "5544374-2",
  "company": "CONTADOR PEREZ JUAN ARTURO"
}, {
  "rut": "6061141-6",
  "company": "CONTRERAS COFRE ARTURO JAVIER"
}, {
  "rut": "13884029-8",
  "company": "CONTRERAS OLAVE HÉCTOR RODRIGO"
}, {
  "rut": "10273448-3",
  "company": "CORREA LABARCA PAULO LUIS"
}, {
  "rut": "13191650-7",
  "company": "CURY PASTENE PATRICIO KAMAL"
}, {
  "rut": "10325420-5",
  "company": "DE LA CUADRA CUADRA FRANCISCO JAVIER"
}, {
  "rut": "11140005-9",
  "company": "DE BEER SORIANO CARLOS JAVIER"
}, {
  "rut": "5665556-5",
  "company": "DE LA CUADRA TAGLE PATRICIO ELIAS"
}, {
  "rut": "5401834-7",
  "company": "DE LA HORRA CORREA PATRICIO ENRIQUE"
}, {
  "rut": "7024081-5",
  "company": "DUHALDE BEDDIG JUAN PABLO"
}, {
  "rut": "8713147-5",
  "company": "ECHEVERRIA ARIAS RODRIGO ANTONIO"
}, {
  "rut": "10400140-8",
  "company": "FERREIRA YAÑEZ PAOLA LUISA"
}, {
  "rut": "23553149-6",
  "company": "FONDERE LUZON HUGO JAVIER"
}, {
  "rut": "9274030-7",
  "company": "FUENTES RIOSECO JOSE LUIS"
}, {
  "rut": "9978023-1",
  "company": "GALDAMES MORALES HECTOR MAURICIO"
}, {
  "rut": "7926672-8",
  "company": "GARCÉS CONSTANZO RAFAEL EDUARDO"
}, {
  "rut": "7892226-5",
  "company": "GIL RENERE TERESA JOSEFINA"
}, {
  "rut": "10584047-0",
  "company": "GOMEZ AEDO GREGORIO ALEJANDRO"
}, {
  "rut": "8282080-9",
  "company": "GONZALEZ ASTUDILLO JAIME IVAN"
}, {
  "rut": "13704740-3",
  "company": "GONZALEZ BARRAMUÑO JUAN CARLOS"
}, {
  "rut": "11345916-6",
  "company": "GONZALEZ CUEVAS DAVID ALEJANDRO"
}, {
  "rut": "6284937-1",
  "company": "GOÑI GARRIDO CARLOS MANUEL"
}, {
  "rut": "4515688-5",
  "company": "GUAJARDO GUZMAN LUIS ARTURO"
}, {
  "rut": "15401081-5",
  "company": "HANANIAS CASTILLO RODRIGO SCANDAR"
}, {
  "rut": "6278432-6",
  "company": "HARDY NORTON PATRICK JOHN"
}, {
  "rut": "9451128-3",
  "company": "HEISE IRARRAZAVAL VICTOR EDUARDO"
}, {
  "rut": "8836409-0",
  "company": "HOLMGREN DEL PINO HELGA LUZ"
}, {
  "rut": "9214774-6",
  "company": "ILLANES BEZANILLA ANDRES"
}, {
  "rut": "7159543-9",
  "company": "JARA PEREZ FERNANDO ANTONIO"
}, {
  "rut": "13906205-1",
  "company": "JARA VALDERRAMA FERNANDO"
}, {
  "rut": "10488305-2",
  "company": "JIMENEZ VALIENTE CARLOS PATRICIO"
}, {
  "rut": "6455999-0",
  "company": "LAFUENTES GIGOUX OSCAR HUGO"
}, {
  "rut": "7175176-7",
  "company": "LAGNIEL SALAS FLAVIO MARCO"
}, {
  "rut": "14441096-3",
  "company": "LANDIVAR SUAREZ ALFREDO GERARDO"
}, {
  "rut": "14441095-5",
  "company": "LANDIVAR VALDIVIA ALFREDo"
}, {
  "rut": "9746673-4",
  "company": "LARA ALBA ALVARO OLEGARIO"
}, {
  "rut": "8612608-7",
  "company": "LARRAÑAGA JIMENEZ ANDRES ANTONIO"
}, {
  "rut": "9943156-3",
  "company": "LEDERMANN STUTZ FELIPE ANDRES"
}, {
  "rut": "7446258-8",
  "company": "LEIVA TORO DANIEL HERNAN"
}, {
  "rut": "13350287-4",
  "company": "LEYTON NUÑEZ PEDRO FELIPE"
}, {
  "rut": "9499465-9",
  "company": "LINDERMANN HUNTER ROBERTO ALEJANDRO"
}, {
  "rut": "9029394-K",
  "company": "LOPEZ ALTANER HECTOR MARCELO"
}, {
  "rut": "7095842-2",
  "company": "LOPEZ PEREIRA PATRICIO CLAUDIO"
}, {
  "rut": "14582637-3",
  "company": "LOPEZ VASQUEZ FRANCISCO JAVIER"
}, {
  "rut": "5230739-2",
  "company": "MAGAÑA CABEZAS RUBEN PATRICIO"
}, {
  "rut": "6916945-7",
  "company": "MALDONADO MUÑOZ GUILLERMO JOSELIN"
}, {
  "rut": "9769131-2",
  "company": "MANRIQUEZ BURGOS MONICA CECILIA"
}, {
  "rut": "10191670-7",
  "company": "MARCHANT BARRIAS JOHN OSVALDO"
}, {
  "rut": "11324360-0",
  "company": "MARIN BORQUEZ HECTOR HUGO"
}, {
  "rut": "11676174-2",
  "company": "MELLA LABRAÑA MARCELO ANDRES"
}, {
  "rut": "9396868-9",
  "company": "MELO GONZALEZ JUAN CARLOS"
}, {
  "rut": "13943632-6",
  "company": "MIÑO CATALÁN DANTE ESTEBAN"
}, {
  "rut": "9476442-4",
  "company": "MONTT GONZALEZ JORGE ALDO"
}, {
  "rut": "12254778-7",
  "company": "MORALES MORANDE JUAN CARLOS"
}, {
  "rut": "12448545-2",
  "company": "MUÑOZ QUIÑONES LEONARDO ANTONIO"
}, {
  "rut": "15997110-4",
  "company": "NIERAAD NAIMAN ALFONSO ALEJANDRO"
}, {
  "rut": "8686261-1",
  "company": "OLIVAREZ ABARZUA LUIS FRANCISCO"
}, {
  "rut": "7044195-0",
  "company": "ORTEGA PALACIOS FE LORENA"
}, {
  "rut": "12660041-0",
  "company": "PAREDES ESPINOZA MARCELO IVAN"
}, {
  "rut": "9743180-9",
  "company": "PEREZ MARINKOVIC JUAN JOSE"
}, {
  "rut": "12462629-3",
  "company": "POBLETE SALAS RODRIGO ANDRES"
}, {
  "rut": "10658174-6",
  "company": "PORTILLA ALARCON PABLO CESAR"
}, {
  "rut": "9672913-8",
  "company": "QUIROGA RIOBO JUAN PABLO"
}, {
  "rut": "9403400-0",
  "company": "RAMIREZ SERRANO LUIS HERNAN"
}, {
  "rut": "12868950-8",
  "company": "REYES BENÍTEZ OCTAVIO ENRIQUE"
}, {
  "rut": "4772904-1",
  "company": "RISOPATRON VERGARA JAIME PANCRACIO"
}, {
  "rut": "8826675-7",
  "company": "RIVERA ONETTO CRISTIAN DARIO"
}, {
  "rut": "4945025-7",
  "company": "RIVEROS OVALLE LUIS RENATO ÁLVARO"
}, {
  "rut": "8832037-9",
  "company": "RODRIGUEZ GASULLA-ECHAGUE JUAN IGNACIO"
}, {
  "rut": "7811204-2",
  "company": "ROJAS MARTINEZ CLAUDIO ESTEBAN"
}, {
  "rut": "12885077-5",
  "company": "ROJAS MUÑOZ CRISTIAN RAUL"
}, {
  "rut": "16887310-7",
  "company": "ROMAN GOMEZ RODRIGO ANDRE"
}, {
  "rut": "7315677-7",
  "company": "ROTELLA MACCHIAVELLO ARNALDO ROBERTO"
}, {
  "rut": "5586823-9",
  "company": "SAAVEDRA URIZAR VICTOR ALBERTO"
}, {
  "rut": "5711650-1",
  "company": "SALAZAR SWETT GONZALO RICARDO"
}, {
  "rut": "7423079-2",
  "company": "SAN MARTIN ARAYA EINAR CLAUDIO"
}, {
  "rut": "7871135-3",
  "company": "SANHUEZA RIQUELME PEDRO CELESTINO"
}, {
  "rut": "6061166-1",
  "company": "SILVA DIBARRART PEDRO PABLO"
}, {
  "rut": "7524524-6",
  "company": "SOTO MARIN BENJAMIN GUSTAVO"
}, {
  "rut": "8161169-6",
  "company": "SWAIN SMITH ALAN PATRICK"
}, {
  "rut": "7018639-K",
  "company": "VALDIVIESO FERNANDEZ JUAN PABLO"
}, {
  "rut": "10151232-0",
  "company": "VALDÉS GÓMEZ PAOLO ALEJANDRO"
}, {
  "rut": "12248923-K",
  "company": "VALDÉS RODRÍGUEZ PATRICIO ANDRÉS"
}, {
  "rut": "6621878-3",
  "company": "VARGAS INOSTROZA VICTOR HUGO"
}, {
  "rut": "9366564-3",
  "company": "VARGAS JERIA FRANCISCO JAVIER"
}, {
  "rut": "12005422-8",
  "company": "VERGARA LIRA JOSE FRANCISCO"
}, {
  "rut": "5335466-1",
  "company": "VERGARA MORENO AURELIO SEGUNDO"
}, {
  "rut": "10985204-K",
  "company": "VERGARA SOTO CRISTIAN RODRIGO"
}, {
  "rut": "7461704-2",
  "company": "VILLARROEL LEVITUREO JUAN ANDRES"
}, {
  "rut": "2597745-9",
  "company": "VIOLLER LARRAIN LUIS EDUARDO"
}, {
  "rut": "6890074-3",
  "company": "VIOLLIER VIAL EDUARDO"
}, {
  "rut": "6062613-8",
  "company": "VOLLMER GARCIA OSCAR FERNANDO"
}, {
  "rut": "10919806-4",
  "company": "YAÑEZ GOMEZ JAVIER ORNALDO"
}, {
  "rut": "10810368-K",
  "company": "ZAPATA VALENZUELA FELIPE ANDRÉS"
}];
export default [].concat(naturalPersons, juridicalPersons);